import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/min.css'
import Rem from '@/assets/js/min.js'
import * as dd from 'dingtalk-jsapi';
import Axios from 'axios'
Vue.prototype.$axios = Axios
Axios.defaults.baseURL = '/api'
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')
Rem.setFontSize();