<template>
  <div class="box" v-if="bodytrue">
    <div class="box_title">
      <div
        class="flx"
        style="justify-content: space-between; color: rgba(255, 255, 255, 0.5)"
      >
        <div style="width: 97%; margin: 0 auto; margin-top: 20px"></div>
      </div>
    </div>
    <div class="box_tle">重点任务</div>
    <div class="box_one flx">
      <div>
        <ul class="uls_one">
          <li class="uls_one_i" style="margin-left: 0.2rem">
            <div>
              <p style="margin: 0">
                <img
                  src="@/assets/images/thought.png"
                  alt=""
                  style="padding: 0.01rem"
                />
              </p>
              <p style="margin: 0.03rem 0px 0px 0.05rem">上级批示</p>
            </div>
          </li>
          <li>
            <p>
              <img src="@/assets/images/science.png" class="uls_img" alt="" />
            </p>
            <p>&nbsp;&nbsp;"美丽台州"守护行动</p>
          </li>
          <li>
            <p>
              <img src="@/assets/images/science.png" class="uls_img" alt="" />
            </p>
            <p>城市管理精细化提升行动</p>
          </li>
          <li>
            <p>
              <img src="@/assets/images/science.png" class="uls_img" alt="" />
            </p>
            <p>城市基层治理效能提升行动</p>
          </li>
          <li>
            <p>
              <img src="@/assets/images/science.png" class="uls_img" alt="" />
            </p>
            <p>综合行政执法争先创优行动</p>
          </li>
          <li>
            <p>
              <img src="@/assets/images/science.png" class="uls_img" alt="" />
            </p>
            <p>执法管理数字化转型行动</p>
          </li>
          <li>
            <p>
              <img src="@/assets/images/science.png" class="uls_img" alt="" />
            </p>
            <p>现代化执法管理铁军锻造行动</p>
          </li>
          <li class="btncor" style="margin-left: 0.2rem">
            <p style="margin-left: 0.29rem">
              <img src="@/assets/images/work.png" class="uls_img" alt="" />
            </p>
            <p>年度工作</p>
          </li>
          <li class="btncor" style="margin-left: 0.2rem">
            <p style="margin-left: 0.29rem">
              <img src="@/assets/images/park.png" class="uls_img" alt="" />
            </p>
            <p>营商环境</p>
          </li>
          <li class="btncor" style="margin-left: 0.2rem">
            <p style="margin-left: 0.29rem">
              <img src="@/assets/images/budget.png" class="uls_img" alt="" />
            </p>
            <p>年度预算情况</p>
          </li>
        </ul>
      </div>
      <div>
        <div class="ifimage" style="position: relative">
          <img src="@/assets/2/95(1).png" alt="" />
          <ul class="algha">
            <li v-for="(item, index) in tab" :key="index" @click="tabs(index)">
              {{ item }}
            </li>
          </ul>
        </div>

        <div class="ifimage" style="width: 100%">
          <img src="@/assets/2/21.png" alt="" v-if="istab === 0" />
          <img src="@/assets/images/numpark.png" v-if="istab === 1" alt="" />
          <img
            src="@/assets/images/numsanitation.png"
            v-if="istab === 2"
            alt=""
          />
          <img
            src="@/assets/images/numtenement.png"
            v-if="istab === 3"
            alt=""
          />
          <img src="@/assets/images/numlaw.png" v-if="istab === 4" alt="" />
          <img
            src="@/assets/images/looks.jpg"
            @click="istabm(0)"
            v-if="istab === 5 && tabm"
            alt=""
          />
          <img
            src="@/assets/images/citys.jpg"
            @click="istabm(1)"
            v-if="istab === 5 && !tabm"
            alt=""
          />
          <!-- <ul class="algha" style="top:5.7rem;height:30px;width:67%;right:0.8rem">
            <li></li>
            <li  ></li>
          </ul> -->
        </div>
      </div>
      <div>
        <div class="box_btn" style="font-size: 0.18rem">
          <div class="box_btn_blue" @click="clkimg(0)">
            <div>
              <p>
                <img src="@/assets/2/govern.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">城市治理系统</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(1)">
            <div>
              <p>
                <img src="@/assets/2/salve.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">易捞点检测</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(2)">
            <div>
              <p>
                <img src="@/assets/2/traffic.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">静态交通综合</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(3)">
            <div>
              <p>
                <img src="@/assets/2/stop2.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">人行道违停处理</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(4)">
            <div>
              <p>
                <img src="@/assets/2/control.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">智慧城管</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(5)">
            <div>
              <p>
                <img src="@/assets/2/sun.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">阳光执法</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(6)">
            <div>
              <p>
                <img src="@/assets/2/cityso.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">智能物业监管</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(7)">
            <div>
              <p>
                <img src="@/assets/2/lj.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">建筑垃圾监管</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(8)">
            <div>
              <p>
                <img src="@/assets/2/record.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">执法全过程记录</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(9)">
            <div>
              <p>
                <img src="@/assets/2/drive.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">城管驾驶舱</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(10)">
            <div>
              <p>
                <img src="@/assets/2/punish.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">行政处罚办案</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="box_btn" style="font-size: 0.18rem">
          <div class="box_btn_blue" @click="clkimg(11)">
            <div>
              <p>
                <img src="@/assets/2/construction.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">智慧城建</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(12)">
            <div>
              <p>
                <img src="@/assets/2/organization.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">防涝防台检测系统</p>
            </div>
          </div>

          <div class="box_btn_blue" @click="clkimg(13)">
            <div>
              <p>
                <img src="@/assets/2/pollute.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">污水</p>
            </div>
          </div>

          <div class="box_btn_blue" @click="clkimg(14)">
            <div>
              <p>
                <img src="@/assets/2/stop.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">智慧停车</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(15)">
            <div>
              <p>
                <img src="@/assets/2/zh.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">城市建设综合管理</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(16)">
            <div>
              <p>
                <img src="@/assets/2/wc.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">城建一张图(公厕)</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(17)">
            <div>
              <p>
                <img src="@/assets/2/oa.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">OA</p>
            </div>
          </div>
          <div class="box_btn_blue" @click="clkimg(18)">
            <div>
              <p>
                <img src="@/assets/2/town.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">市局官网</p>
            </div>
          </div>
          <!-- -->
          <div class="box_btn_blue" @click="clkimg(19)">
            <div>
              <p>
                <img src="@/assets/2/education.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">在线教育系统</p>
            </div>
          </div>

          <div class="box_btn_blue" @click="clkimg(20)">
            <div>
              <p>
                <img src="@/assets/2/law.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">城管执法智联中心</p>
            </div>
          </div>
          <!-- <div class="box_btn_blue" @click="clkimg(22)">
            <div>
              <p>
                <img src="@/assets/2/防汛抗旱.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">防汛抗旱</p>
            </div>
          </div> -->
          <!-- <div class="box_btn_blue" @click="clkimg(21)">
            <div>
              <p>
                <img src="@/assets/2/智慧物业监管.png" alt="" />
              </p>
              <p style="font-size: 0.18rem">智慧物业</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <!-- <h5>请检查输出环境</h5> -->
    <img style="width: 100%" src="@/assets/images/404.jpg" alt="" />
  </div>
</template>

<script>
import dd from "gdt-jsapi";
import axios from "axios";
const jsons = [
  "http://10.49.147.21:8000/oa/?ddtab=true", //0
  "http://10.49.146.85:9290/login?ddtab=true", //1
  "http://10.49.2.98/TPublic/home/login.htm?ddtab=true", //2
  "http://10.49.9.218:8089/fxc/login.do?act=login&mode=public&location=?ddtab=true", //3
  "http://183.131.126.69:7001/logontzcg/login.htm?ddtab=true", //4
  "", //5
  "http://47.97.43.235:3000/?ddtab=true", //6
  "https://36.134.7.135:10443/portal/?ddtab=true", //7
  "", //8
  "https://hmfk.zjyjx.cn/CityGovern/login?ddtab=true", //9
  "http://223.4.72.166:7081/#/passport/login?ddtab=true", //10
  "http://223.4.64.110/web/login;JSESSIONID=f5917173-49c1-4f79-8049-2581f6aa02e2?ddtab=true", //11
  "https://flft.jst.zj.gov.cn/#/?ddtab=true", //12
  "http://10.49.146.97:8001/tzyq/root/realtime_monitor?ddtab=true", //13
  "http://223.4.64.110/web/index/slxt@2?ddtab=true", //14
  "http://10.49.2.98/TPublic/home/login.htm?ddtab=true", //15
  "http://223.4.64.110/web/login?ddtab=true", //16
  "https://yzt.jst.zj.gov.cn:8010/#/?ddtab=true", //17
  "http://work.zjtz.gov.cn/#/login?ddtab=true", //18
  "http://zfj.zjtz.gov.cn/?ddtab=true", //19
  // ''
  "http://10.49.9.214/?ddtab=true", //20
  "http://111.3.65.91:8081/wdk/frame/permaint/login_admin.jsp?ddtab=true", //21
  // 'http://10.49.146.85:9290/login?ddtab=true',//22
  // 'http://47.97.43.235:3000/?ddtab=true'//23
];
// const apis = 'https://openplatform.dg-work.cn'
// const axios = require('axios')
export default {
  name: "HelloWorld",
  data() {
    return {
      istab: 0,
      tabm: 0,
      tab: ["", "", "", "", "", ""],
      users: {},
      bodytrue: true,
      ddresult: {},
      appid: null,
    };
  },

  methods: {
    tabs(index) {
      this.istab = index;
    },
    istabm(index) {
      this.tabm = !this.tabm;
    },
    clkimg(index) {
      console.log(jsons.length);
      dd.openLink({
        url: jsons[index],
      }).then((res) => {
        console.log(res);
      });
    },
  },
  beforeCreate() {
    let _this = this;

    dd.ready(function () {
      // alert("页面开始初始化");
      dd.getAuthCode({}).then((result) => {
        if (result) {
          this.ddresult = result;
          let params = {
            appkey: "zhzfjhome-F0c9NwU9X44nrLnEryAb",
            appsecret: "95h3npoD5P8Pc3UW4PnR9p3K5EyLD3GW616q4j78",
          };
          axios
            .post("http://39.175.64.217:1011/api/admin/dingAccessToken", {
              params,
            })
            .then((res) => {
              this.appid = res.data;
              _this.$axios
                .get("/rpc/oauth2/dingtalk_app_user.json", {
                  params: {
                    access_token: this.appid,
                    auth_code:this.ddresult.auth_code
                  },
                })
                .then(function (response) {
                  // handle success
                  console.log(response);
                })
                .catch(function (error) {
                  // handle error
                  console.log(error);
                });
            });
        }
      });

      //调用jsapi方法
      dd.getLoginUser()
        .then((res) => {
          // alert("方法调用成功", res);
          _this.users = res;
          dd.getAuthCode({
            corpId: _this.users.accountId,
          })
            .then((res) => {
              // console.log(res)
            })
            .catch((err) => {
              _this.bodytrue = false;
            });
        })
        .catch((err) => {
          _this.bodytrue = false;
          // console.error("调用不成功", err);
          // alert('调用不成功'+err)
        });
    });
  },
  updated() {
    (function (doc, win) {
      var docEl = doc.documentElement,
        resizeEvt =
          "orientationchange" in window ? "orientationchange" : "resize",
        recalc = function () {
          if (docEl.clientWidth > 750) {
            docEl.style.fontSize = "100px";
            // doc.getElementById("box").style.width = "750px";
          } else {
            var width = docEl.clientWidth / 7.5;
            docEl.style.fontSize = width + "px";
            // doc.getElementById("box").style.width = "auto";
          }
        };
      if (!doc.addEventListener) return;
      win.addEventListener(resizeEvt, recalc, false);
      doc.addEventListener("DOMContentLoaded", recalc, false);
    })(document, window);
  },
};
</script>

<style scoped>
</style>
